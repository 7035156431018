import { FormRow, FormRowSelect } from '.'
import { useAppContext } from '../context/appContext'
import Wrapper from '../assets/wrappers/SearchContainer'
const SearchContainer = () => {
	const {
		isLoading,
		search,
		searchStatus,
		searchAbout,
		sort,
		sortOptions,
		statusOptions,
		aboutOptions,
		handleChange,
		searchFrequency,
		infoFrequencyOptions,
		clearFilters,
	} = useAppContext()

	const handleSearch = (e) => {
		if (isLoading) return
		handleChange({ name: e.target.name, value: e.target.value })
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		clearFilters()
	}

	return (
		<Wrapper>
			<form className='form'>
				<h4>search form</h4>
				{/* search position */}
				<div className='form-center'>
					<FormRow
						type='text'
						name='search'
						value={search}
						handleChange={handleSearch}
					></FormRow>
					{/* rest of the inputs */}
					<FormRowSelect
						labelText='info status'
						name='searchStatus'
						value={searchStatus}
						handleChange={handleSearch}
						list={['all', ...statusOptions]}
					></FormRowSelect>
					{/* search by type */}

					<FormRowSelect
						labelText='info frequency'
						name='searchFrequency'
						value={searchFrequency}
						handleChange={handleSearch}
						list={['all', ...infoFrequencyOptions]}
					></FormRowSelect>

					<FormRowSelect
						labelText='about option'
						name='searchAbout'
						value={searchAbout}
						handleChange={handleSearch}
						list={['all', ...aboutOptions]}
					></FormRowSelect>
					{/* sort */}

					<FormRowSelect
						name='sort'
						value={sort}
						handleChange={handleSearch}
						list={sortOptions}
					></FormRowSelect>
					<button
						className='btn btn-block btn-danger'
						disabled={isLoading}
						onClick={handleSubmit}
					>
						clear filters
					</button>
				</div>
			</form>
		</Wrapper>
	)
}

export default SearchContainer
