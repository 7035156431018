export const DISPLAY_ALERT = 'SHOW_ALERT'
export const CLEAR_ALERT = 'CLEAR_ALERT'

export const SETUP_USER_BEGIN = 'SETUP_USER_BEGIN'
export const SETUP_USER_SUCCESS = 'SETUP_USER_SUCCESS'
export const SETUP_USER_ERROR = 'SETUP_USER_ERROR'

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'

export const LOGOUT_USER = 'LOGOUT_USER'

export const UPDATE_USER_BEGIN = 'UPDATE_USER_BEGIN'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'

export const HANDLE_CHANGE = 'HANDLE_CHANGE'

export const CLEAR_VALUES = 'CLEAR_VALUES'

export const CREATE_INFO_BEGIN = 'CREATE_INFO_BEGIN'
export const CREATE_INFO_SUCCESS = 'CREATE_INFO_SUCCESS'
export const CREATE_INFO_ERROR = 'CREATE_INFO_ERROR'

export const GET_INFO_BEGIN = 'GET_INFO_BEGIN'
export const GET_INFO_SUCCESS = 'GET_INFO_SUCCESS'

export const SET_EDIT_INFO = 'SET_EDIT_INFO'

export const DELETE_INFO_BEGIN = 'DELETE_INFO_BEGIN'

export const EDIT_INFO_BEGIN = 'EDIT_INFO_BEGIN'
export const EDIT_INFO_SUCCESS = 'EDIT_INFO_SUCCESS'
export const EDIT_INFO_ERROR = 'EDIT_INFO_ERROR'

export const SHOW_STATS_BEGIN = 'SHOW_STATS_BEGIN'
export const SHOW_STATS_SUCCESS = 'SHOW_STATS_SUCCESS'

export const CLEAR_FILTERS = 'CLEAR_FILTERS'

export const CHANGE_PAGE = 'CHANGE_PAGE'

export const GET_SUBSCRIBERS_BEGIN = 'GET_SUBSCRIBERS_BEGIN'
export const GET_SUBSCRIBERS_SUCCESS = 'GET_SUBSCRIBERS_SUCCESS'

export const SET_INFO_TO_SEND = 'SET_INFO_TO_SEND'

export const SEND_INFO_BEGIN = 'SEND_INFO_BEGIN'
export const SEND_INFO_SUCCESS = 'SEND_INFO_SUCCESS'
export const SEND_INFO_ERROR = 'SEND_INFO_ERROR'

export const CLEAR_INFO_AFTER_SENDING = 'CLEAR_INFO_AFTER_SENDING'

export const SET_STATUS_SENT_BEGIN = 'SET_STATUS_SENT_BEGIN'
export const SET_STATUS_SENT_SUCCESS = 'SET_STATUS_SENT_SUCCESS'

export const SET_INFO_TO_SEND_STATUS = 'SET_INFO_TO_SEND_STATUS'
